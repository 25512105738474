import Hero from "./Hero";
import React, { PropsWithChildren } from "react";

interface IEyeConditionProps {
  anchor: string;
  title: string;
  last?: boolean;
}

function EyeCondition(props: PropsWithChildren<IEyeConditionProps>) {
  return <>
    <h3 id={props.anchor} className="my-3">{props.title}</h3>
    {props.children}
    {!props.last && <hr />}
  </>;
}

export default function EyeConditions() {
  return <>
    <Hero h1ClassName="mb-md-n1" title="Eye Conditions" />

    <section className="blog-page">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="post-item">
              <div className="post-item-wrap">
                <div className="post-item-desc">
                  <EyeCondition anchor="keratoconus" title="Keratoconus">
                    <p>
                      Keratoconus is an eye condition that causes the cornea to gradually thin and the natural pressure
                      in the eye causes the area of thinning to bulge out into a cone-like shape. The cornea is the
                      clear dome at the front of the eye. The cone distortion of the cornea abnormally redirects light
                      leading to blurred and distorted vision and may also cause sensitivity to light and glare.
                    </p>
                    <p>
                      The condition typically starts in the teens though this may vary between individuals. It will
                      gradually progress over time for a decade or longer. In some cases, there may be complications
                      with the disease where the cornea may swell quickly due to a break down of the inner lining of the
                      cornea (Descemet’s membrane) and possibly cause scarring. Some advanced cases of keratoconus may
                      become scarred at the apex of the cone. Risk factors for keratoconus include family history of the
                      disease, vigorous eye rubbing, a history of allergies in yourself or siblings or a history of
                      connective tissue diseases
                    </p>
                    <p>
                      Treatment of keratoconus is dependent on the severity of the disease at present, the rate of
                      progression and the lifestyle needs of the patient. This may involve spectacles, soft contact
                      lenses, corneal or scleral rigid gas permeable contact lenses, corneal grafts, or corneal
                      crosslinking. Corneal crosslinking (CXL) is a treatment used to stabilise a cornea with
                      progressive keratoconus that is showing increasing cone steepness or corneal thinning.
                    </p>
                    <p>
                      Detection of the condition is possible in the early stages with a thorough and comprehensive eye
                      examination. At Burleigh Optometrists, we are well equipped to diagnose and assess you for
                      keratoconus and advise the course of action that is most suitable for you.
                    </p>
                    <p>
                      For more information: <a href="https://www.keratoconus.org.au"
                                               target="_blank">https://www.keratoconus.org.au</a>
                    </p>
                  </EyeCondition>

                  <EyeCondition anchor="corneal-grafts" title="Corneal Grafts">
                    <p>
                      A corneal graft or corneal transplant surgery is a surgical procedure where donor tissue from
                      another eye is used to improve vision in a patient with diseased or damaged corneas. This
                      procedure is carried out by an ophthalmologist and they may use the full or partial thickness of
                      the donor cornea. Following the procedure, patients may still have residual irregularity. In these
                      circumstances, we at Burleigh Optometrists will work with your surgical ophthalmologist to assess
                      your eye health and visual needs to determine what visual aids you may need including hard contact
                      lenses or spectacles.
                    </p>
                  </EyeCondition>

                  <EyeCondition anchor="myopia" title="Myopia">
                    <p>
                      Myopia is where a person has blurry long-distance vision, often called ‘short-sightedness’ or
                      ‘near-sightedness’. A person with myopia can typically see clearly up close – for example, when
                      reading a book – but words and objects look fuzzy on a whiteboard, across the room, or when
                      driving.
                    </p>
                    <p>
                      The rate of myopia is increasing across the world; from 22% of the world’s population in 2000 to
                      33% in 2020. Half of the world’s population expected to be myopic by 2050. Most myopia is caused
                      by the eye length growing too quickly in childhood. The eyes are meant to grow from birth until
                      the early teens and then cease, but in myopia the eyes grow too much and/or continue growing into
                      the teenage years.
                    </p>
                    <p>
                      Myopia progresses fastest in younger children, especially those under age 10. This means that
                      the most important opportunity to slow eye growth is when children are younger. Myopia management
                      aims to apply specific treatments to slow the excessive eye growth to a lesser rate. Standard
                      spectacles and contact lenses do not slow down the progression of childhood myopia. Our
                      optometrists at Burleigh Optometrists will work with you to build the the most suitable management
                      strategy for you or your child. Treatments we may prescribe include myopia control spectacles,
                      myopia control soft contact lenses, orthokeratology (orthoK) and ocular medical treatment.
                    </p>
                    <p>
                      For more information: <a href="https://mykidsvision.org"
                                               target="_blank">https://mykidsvision.org</a>
                    </p>
                  </EyeCondition>

                  <EyeCondition anchor="amd" title="Macular Degeneration">
                    <p>
                      Age-related macular degeneration (AMD) is a chronic, painless disease of the macula. The macula is
                      the area at the centre of your retina at the back of your eye that allows you to see fine detail
                      such as reading words or looking at faces. The retina is the sensory area inside of the eye.
                    </p>
                    <p>
                      Age-related macular degeneration causes a progressive loss of central vision, while peripheral
                      vision remains intact. Though there is no total blackout loss of vision with macular degeneration,
                      a person with macular degeneration may over time lose the ability to read words in a book,
                      recognise faces and read street signs. Each individual has a different rate of progression of the
                      disease – some may have very slow advancement and not notice significant visual changes, while
                      other may have more rapid progression that leads to central vision loss.
                    </p>
                    <p>
                      There are two main types of age-related macular degeneration – dry and wet.
                    </p>
                    <p>The dry form tends to be slowly progressive over many years. There is some evidence that
                      nutrition and specific supplements may slow down the progression of macular degeneration in some
                      people. Specifically the nutrients lutein, zeaxanthin, omega-3, vitamin C, vitamin E, zinc and
                      selenium. Including foods that contain these nutrients in your diet may be beneficial. It is
                      important to note that this is not a cure and the disease will still progress over time with the
                      rate of progression varying between individuals.
                    </p>
                    <p>
                      The wet (neovascular) form of age-related macular degeneration tends to progress more rapidly with
                      change in vision as soon as fluid is present in the macula and worsening within weeks to months.
                      Dry macular degeneration becomes wet macular degeneration when abnormal blood vessels break
                      through and grow under the retina. These blood vessel leak fluid and/or blood leading to
                      distortion and rapid loss of central vision. There are treatments available for wet age-related
                      macular degeneration, with earlier detection allowing for better vision following treatment.
                    </p>
                    <p>
                      Age-related macular degeneration is the most common macular disease and accounts for half of all
                      blindness and severe vision loss in Australia. All comprehensive eye examinations with our
                      optometrists at Burleigh Optometrists will involve a thorough examination of your macula and an
                      image is taken with an OCT and retinal camera to detect early changes at the macula.
                    </p>
                    <p>
                      For more information: <a href="https://www.mdfoundation.com.au"
                                               target="_blank">https://www.mdfoundation.com.au</a>
                    </p>
                  </EyeCondition>

                  <EyeCondition anchor="diabetic-retinopathy" title="Diabetic Retinopathy">
                    <p>
                      Diabetic retinopathy is caused by damage to the small blood vessels in the retina from diabetes.
                      It is the leading cause of avoidable vision loss and blindness in working-age Australians. Most
                      people with diabetes will develop diabetic retinopathy over time. The severity of the condition
                      determines the effect on your vision and is greatly influenced by how well your diabetes is
                      controlled.
                    </p>
                    <p>
                      Diabetic retinopathy typically starts with non-proliferative diabetic retinopathy where there is
                      damage to the small blood vessels in the retina due to increased blood glucose levels. These
                      damaged vessels leak fluid or blood and some may become blocked and form microaneurysms. These
                      changes may or may not affect your clarity of vision, but the severity of the non-proliferative
                      diabetic retinopathy determines the frequency of review as severe disease is likely to progress to
                      proliferative diabetic retinopathy.
                    </p>
                    <p>
                      Proliferative diabetic retinopathy occurs when there is growth of new fragile blood vessels in
                      areas of the retina that are lacking oxygen supply. Unfortunately, the fragility of these vessels
                      causes them to leak and bleed, affecting vision and causing scarring if left untreated. The
                      scarring of the retina can contract over time and lead to a retinal detachment and blindness. This
                      makes close monitoring of non-proliferative diabetic retinopathy essential to ensure timely
                      referral for treatment of early proliferative disease.
                    </p>
                    <p>
                      Diabetic macular oedema is when the damage to the small blood vessels causing fluid to leak is
                      located in or around the macula. This can occur at any stage of diabetic retinopathy. The fluid
                      accumulating in the macula leads to distortion of your central vision and may eventually lead to
                      loss of central vision if left untreated.
                    </p>
                    <p>
                      At Burleigh Optometrists, our comprehensive diabetic eye assessments include dilated eye
                      examinations, imaging of the retina and OCT of the macula to ensure early detection of diabetic
                      retinopathy. It is our passion to work with you and help to educate you about the eye health
                      implications of your diabetic condition.
                    </p>
                    <p>
                      For more information: <a
                      href="https://www.mdfoundation.com.au/about-macular-disease/diabetic-eye-disease/overview"
                      target="_blank">
                      https://www.mdfoundation.com.au/about-macular-disease/diabetic-eye-disease/overview
                    </a>
                    </p>
                  </EyeCondition>

                  <EyeCondition anchor="glaucoma" title="Glaucoma">
                    <p>
                      Glaucoma is the name for the groups of eye diseases that cause damage to the optic nerve leading
                      to
                      vision loss. Vision loss from glaucoma is usually painless and gradual with half of patients who
                      have early glaucoma unaware of the disease until significant peripheral vision loss has occurred.
                      Damage to the optic nerve and the subsequent vision loss from glaucoma is irreversible, making
                      early
                      detection and preventative care essential as there is no cure for glaucoma.
                    </p>
                    <p>
                      Increased risk for glaucoma developing include family history of glaucoma, high eye pressure, aged
                      over 50 years old (however, there are younger people who can also have glaucoma), diabetes,
                      moderate
                      to high short or long-sightedness, prolonged use of corticosteroid medication, migraine suffer,
                      past
                      eye surgery, past eye or head injury, high or low blood pressure and sleep apnoea. It is important
                      to note that some people may develop glaucoma despite having none of these risk factors.
                    </p>
                    <p>
                      Glaucoma is a complex disease that requires a thorough examination of the eyes and critical
                      analysis of the patient’s whole eye and systemic health. Determining whether a patient has
                      glaucoma
                      requires assessment of the optic nerves with a slit lamp and retinal camera, OCT of the optic
                      nerves, the drainage angle, the thickness of your cornea, and intraocular pressure. If any those
                      results are suspicious, performance of automated perimetry (visual field test) shows if there has
                      been any loss of peripheral vision.
                    </p>
                    <p>
                      For some people, they may be told they are glaucoma suspects. To be a glaucoma suspect means that
                      there are signs that your optometrist has seen during testing that appear to be glaucoma-like, but
                      you have suffered no clinically detectable loss of vision. Your optometrist will closely monitor
                      for
                      changes in these signs and may detect glaucoma in its earliest stages so that you may commence
                      treatment before any noticeable vision loss has occurred.
                    </p>
                    <p>
                      Despite there being no cure for glaucoma and no way to restore vision already lost from glaucoma,
                      there are treatments available to slow down and even stop progression of the disease. The earlier
                      the diagnosis, the more effective the treatments. These treatments include the use of lasers,
                      prescription eye drops, surgery, and/or management of systemic health concerns. Glaucoma specific
                      treatments focus on the reduction of your intraocular pressure (IOP). Reducing the pressure in the
                      eye takes the stress off the optic nerve to slow down or prevent further deterioration of the
                      optic
                      nerve.
                    </p>
                    <p>
                      At Burleigh Optometrists, every comprehensive examination will include thorough assessment of your
                      eye health including slit lamp examinations, retinal photography, OCT, intraocular pressure
                      assessment, and, if required, corneal thickness measurements and automated perimetry for early
                      detection of glaucoma.
                    </p>
                    <p>
                      For more information: <a href="https://glaucoma.org.au"
                                               target="_blank">https://glaucoma.org.au</a>
                    </p>
                  </EyeCondition>

                  <EyeCondition anchor="dry-eyes" title="Dry Eyes">
                    <p>
                      Dry Eye disease is a condition where your tears do not provide sufficient lubrication for your
                      eyes. There are many reasons your tears may be inadequate and unstable which leads to varying
                      symptoms dependent on the underlying cause. The instability can lead to inflammation and damage
                      the eye surface.
                    </p>
                    <p>
                      Dry eyes can feel uncomfortable or lead to symptoms of blurry vision or tired eyes. Common
                      symptoms include grittiness, stinginess, wateriness, itchiness, burning, or blurry vision. Some
                      environments may exacerbate these symptoms such as windy days, air-conditioned rooms and being in
                      an airplane. Some tasks such as looking at screens for extended periods of time may also cause dry
                      eyes. In some cases, medications or general health issues may also cause dry eyes.
                    </p>
                    <p>
                      People may have similar symptoms, but different underlying causes. Appropriate treatment of the
                      underlying cause is required to provide relief for your individual case of dry eyes. At Burleigh
                      Optometrists, our optometrists will perform a thorough assessment of your eye surface with
                      consideration of your general health to determine what you need to treat your dry eyes. Treatment
                      may include eye drops, eye ointments, eye lid cleansers or in-room treatments of Blephasteam with
                      meibomian gland expression.
                    </p>
                  </EyeCondition>

                  <EyeCondition anchor="eye-allergies" title="Eye Allergies, Itchy Eyes and Hay Fever">
                    <p>
                      Eye allergies develop as a response to something in the environment. It may present with
                      itchiness, redness, burning and watery eyes. It occurs when the body’s immune system becomes
                      sensitised and overreacts to an allergen that has come into contact with the eye surface. Common
                      allergens may be pollen, dust, pet dander, perfume or smoke.
                    </p>
                    <p>
                      Some people may experience heightened eye allergies during seasons they experience other hay
                      fever symptoms such as sneezing or stuffy nose from nasal allergies. Management of exposure to
                      allergens can reduce the intensity of your eye allergies. Some tips for managing allergies include
                      closing windows during high pollen periods, wearing glasses or sunglasses outdoors to protect the
                      eyes and washing your hands after petting animals.
                    </p>
                    <p>
                      Treatment of the symptoms of eye allergies is dependent on the severity and the cause of your
                      allergies. This may include eye drops such as lubricants, decongestants, anti-histamines, mast
                      cell stabilisers, corticosteroids, non-steroidal anti-inflammatory drugs (NSAIDs) and
                      immunomodulator drops. Our optometrists at Burleigh Optometrists are therapeutically endorsed and
                      able to prescribe prescription eye drops that are most suitable for you.
                    </p>
                  </EyeCondition>

                  <EyeCondition anchor="blepharitis" title="Blepharitis" last>
                    <p>
                      Blepharitis is inflammation of the eyelids. It usually affects both eyes along the eyelid
                      margins. Blepharitis is most commonly caused by infection of the eyelids by bacteria or microbes
                      or blockage of glands in the eyelids, though some diseases and medications may also cause
                      blepharitis.
                    </p>
                    <p>
                      Blepharitis is a chronic condition that can be difficult to treat. It can flare up in waves over
                      time and can cause you significant discomfort. Some signs and symptoms of blepharitis include
                      redness along the eyelid margins and of the eyes, crustiness of the eyelids, greasy eyelids,
                      flakey eyelids, swollen eyelids, itchiness, irritation and watery eyes. Blepharitis can often lead
                      to dry eyes, styes and recurrent conjunctivitis.
                    </p>
                    <p>
                      At Burleigh Optometrists, an assessment with our optometrists can help you manage your
                      blepharitis to minimise the discomfort of your symptoms. Treatment can include specific eye lid
                      cleansers dependent on the cause of your blepharitis and in room treatments of Zocular Eyelid
                      System Technology (ZEST).
                    </p>
                  </EyeCondition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
}