import React from 'react'
import Hero from './Hero';
import Map from './Map';

export default class Contact extends React.Component {
    render() {
        // HACK
        const boxBackgroundImage = require('./Style/images/bg/eyetest.jpg');

        return (
            <>
                <Hero preTitle="(07) 5535 5999" title="Contact Us" />

                <section className="contact-page">
                    <div className="container">
                        {/*
                        <div className="row">
                            <div className="col-12 col-lg-10 mx-auto">
                                <div className="title text-center">
                                    <h2>Our website is launching soon</h2>
                                    <p className="mb-0" style={{ color: '#343a40' }}>
                                        Boyce Optometry has undergone a name change to Burleigh Optometrists!
                                        We thank you for your patience while we build our new website.
                                        Our practice is open for examinations so contact us today to meet our optometrists, John Boyce and Rosine Ung.
						            </p>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="row ">
                            <div className="col-md-4 mb-5">
                                <div className="contact-box h-100 bg-overlay-dark-7 px-3 py-4" style={{
                                    background: 'url(' + boxBackgroundImage + ') no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center'
                                }}>
                                    {/* Address */}
                                    <div className="contact-info all-text-white">
                                        <div className="contact-box-icon"><i className="fas fa-map-marked"></i></div>
                                        <h5 className="mb-2">Address</h5>
                                        <p>
                                            Shop 2, 57 James Street<br />
                                            Burleigh Heads QLD 4220
							            </p>
                                    </div>
                                    {/* Email */}
                                    <div className="contact-info all-text-white">
                                        <div className="contact-box-icon"><i className="fas fa-envelope"></i></div>
                                        <h5 className="mb-2">E-mail</h5>
                                        <a href="mailto:info@burleighoptom.com.au">info@burleighoptom.com.au</a>
                                    </div>
                                    {/* Phone */}
                                    <div className="contact-info all-text-white">
                                        <div className="contact-box-icon"><i className="fas fa-phone"></i></div>
                                        <h5 className="mb-2">Phone</h5>
                                        <p>(07) 5535 5999</p>
                                    </div>
                                    {/* Fax */}
                                    <div className="contact-info all-text-white">
                                        <div className="contact-box-icon"><i className="fas fa-fax"></i></div>
                                        <h5 className="mb-2">Fax</h5>
                                        <p>(07) 5576 4238</p>
                                    </div>
                                </div>
                            </div>
                            {/* Map */}
                            <div className="col-md-8 mb-5">
                                <Map />
                            </div>
                        </div>

                        {/* Form */}
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <div className="h-100">
                                    <h3>Online Enquiries</h3>

                                    <p>If you have a question or want to book an appointment please complete this form and we'll get back to you as soon as we can.</p>

                                    <form className="contact-form" id="contact-form" name="contact" method="post">
                                        <input type="hidden" name="form-name" value="contact" />

                                        <div className="row">
                                            <div className="col-md-6">
                                                <span className="form-group">
                                                    <input id="con-name" name="name" type="text" className="form-control" placeholder="Name" />
                                                </span>
                                            </div>

                                            <div className="col-md-6">
                                                <span className="form-group">
                                                    <input id="con-email" name="email" type="email" className="form-control" placeholder="Email" />
                                                </span>
                                            </div>

                                            <div className="col-md-12">
                                                <span className="form-group">
                                                    <input id="con-subject" name="subject" type="text" className="form-control" placeholder="Subject" />
                                                </span>
                                            </div>

                                            <div className="col-md-12">
                                                <span className="form-group">
                                                    <textarea id="con-message" name="message" cols={40} rows={6} className="form-control" placeholder="Message"></textarea>
                                                </span>
                                            </div>

                                            <div className="col-md-12 text-center">
                                                <button className="btn btn-dark btn-block" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}