import React, { useEffect } from "react";
import { Switch, Route, useLocation, BrowserRouter } from "react-router-dom";
import About from "./About";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Contact from "./Contact";
import Services from "./Services";
import EyeConditions from "./EyeConditions";

function Routes() {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 100);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <>
      <Header />
      <main>
        <Switch>
          <Route exact path="/">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/eye-conditions">
            <EyeConditions />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
        </Switch>
      </main>
      <Footer />
    </>
  );
}

export default function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.async = true;
    script.src = "https://cdn.hotdoc.com.au/static/assets/js/hotdoc-widgets.min.js";

    document.head.appendChild(script);
  }, []);

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}
