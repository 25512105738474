import * as React from 'react';
import { NavLink } from 'react-router-dom';
import HotDoc from '../HotDoc';

export default class Navigation extends React.Component {
    render() {
        // HACK
        var logo = require('../Style/images/logo-icon.png');

        return (
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <NavLink exact to="/" className="navbar-brand">
                        <div className="navbar-brand-item">
                            <img src={logo} width="44" height="44" alt="Burleigh Optometrists" />
                            &nbsp;
                            Burleigh Optometrists
                        </div>
                    </NavLink>

                    <button className="navbar-toggler ml-auto collapsed"
                        type="button" data-toggle="collapse" data-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"> </span>
                    </button>

                    <div className="navbar-collapse collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <NavLink exact to="/" className="nav-link">About</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/contact" className="nav-link">Contact</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/eye-conditions" className="nav-link">Eye Conditions</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/services" className="nav-link">Services</NavLink>
                            </li>
                            <li className="nav-item d-none d-xl-block">
                                <a className="nav-link" href="/referral-form.pdf">
                                    Referral Form <i className="fas fa-download"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <div className="nav-link text-center">
                                    <div className="my-4 my-lg-0">
                                        <HotDoc />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}