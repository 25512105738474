import React from "react";

type HeroProps = {
  h1ClassName?: string,
  preTitle?: string,
  title: string
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default class Hero extends React.Component<HeroProps> {
  render() {
    const h1ClassName = this.props.h1ClassName ? this.props.h1ClassName : "mb-md-n4";
    const headerBackgroundImage = require("./Style/images/bg/burleigh.jpg");
    const nbsp = "\u00A0";

    return (
      <div className="text-center bg-overlay-dark-4" style={{
        background: "url(" + headerBackgroundImage + ") no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }}>
        <div className="container">
          <div className="row all-text-white">
            <div className="col-md-12 align-self-center">
              <h6 className="display-6 mt-9 mb-0">
                {this.props.preTitle || nbsp}
              </h6>
              <h1 className={classNames("font-weight-bold display-4 display-md-1 mb-2", h1ClassName)}>
                {this.props.title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}