import React from 'react';
import Hero from './Hero';

export default class Services extends React.Component {
    render() {
        return (
            <>
                <Hero title="Services" />

                {/* Page content */}
                <section>{/* Hack for spacing */}</section>

                <section className="why-us p-0">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className=" col-md-12 bg-grad px-4 py-5 p-lg-5 all-text-white">
                                <div className="h-100">
                                    <div className="title text-left p-0">
                                        <h2>Our Services</h2>
                                    </div>
                                    <div className="row" style={{ fontWeight: "bold" }}>
                                        <div className="col">
                                            <ul className="list-group list-group-borderless text">
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Comprehensive Eye Examinations
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Glaucoma Assessment and Monitoring
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Macular Degeneration Assessment and Monitoring
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Diabetic Eye Examinations
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Children's Eye Examinations
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col">
                                            <ul className="list-group list-group-borderless text">
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Contact Lens Fitting
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Advanced Contact Lens Fitting
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Orthokeratology
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Myopia Management
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Dry Eye Therapy
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>{/* Hack for spacing */}</section>

                <section className="why-us p-0">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className=" col-md-12 bg-grad px-4 py-5 p-lg-5 all-text-white">
                                <div className="h-100">
                                    <div className="title text-left p-0">
                                        <h2>Our Diagnostic Equipment</h2>
                                    </div>
                                    <div className="row" style={{ fontWeight: "bold" }}>
                                        <div className="col">
                                            <ul className="list-group list-group-borderless text">
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Optical Coherence Tomography (OCT)
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Retinal Scan
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Corneal Topographer
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Visual Field Analyser
                                                </li>
                                                <li className="list-group-item text-white">
                                                    <i className="fa fa-check"></i>Contact Lens Trial Kits
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>{/* Hack for spacing */}</section>
            </>


        );
    }
}