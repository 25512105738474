import React from 'react';
import Hero from './Hero';
import HotDoc from './HotDoc';

export default class About extends React.Component {
    render() {
        // Hack
        var team = require('./team.jpg');

        return (
            <>
                <Hero title="Welcome" />

                <section className="blog-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <div className="post-item">
                                    <div className="post-item-wrap">
                                        <div className="post-image">
                                            <img src={team} alt="Team" />
                                        </div>
                                        <div className="post-item-desc">
                                            <p>Burleigh Optometrists is a family owned, independent optometry practice with a passion for excellence in eyecare.</p>

                                            <p>
                                                Our practice has a long history of providing eyecare to the Burleigh Heads community since the 1980's.
                                                It was over 35 years ago that John Boyce acquired and established Boyce Optometry and Contact Lens Centre which in 2019 became Burleigh Optometrists.
                                                John was well known in the community as a caring and thorough optometrist with exceptional skill in fitting complex contact lenses.
                                                John Boyce has retired and entrusted the care of his patients to our principal optometrist, Rosine Ung.
                                            </p>

                                            <p>
                                                If you are considering or currently wear contact or spectacle lenses, our experienced optometrists will work with you to find the lens that best fits your needs.
                                                Complex contact lens fits such as fitting rigid lenses are our passion.
                                                We source our contact and spectacle lenses from numerous laboratories around the country and overseas to ensure your visual needs are met with a customised design that suits you.
                                            </p>

                                            <p>Our experienced dispensing staff are always happy to assist with questions in person, over the phone or via email.</p>

                                            <h5 className="my-3">Rosine Ung</h5>

                                            <p>
                                                Rosine Ung is a therapeutically endorsed optometrist with the ability to prescribe ocular medications.
                                                She graduated from the Queensland University of Technology and has practiced on the Gold Coast, in Brisbane and in regional Australia, including weeks with indigenous populations.
                                                She has often co-managed patients in her practice and has worked as a clinical optometrist for local ophthalmologists.
                                                Her interests include general and complex contact lens fits, ocular diseases, binocular vision treatment and general optometry.
                                            </p>

                                            <h5 className="my-3">Meghan Holzheimer</h5>

                                            <p>
                                                Meghan is a therapeutically endorsed optometrist with a particular interest in myopia control (including ortho-k), chronic ocular disease management and general optometry.
                                                She graduated from Queensland University of Technology and has extensive experience working in ophthalmologist clinics and independent optometrist practices in regional areas as well as on the Gold Coast.
                                                Meghan takes pride in her efficient, but thorough patient care while being friendly and warm.
                                            </p>

                                            <p>&nbsp;</p>

                                            <p className="text-center">
                                                <HotDoc />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}