const HotDoc: React.FC = () =>
    <a href="https://www.hotdoc.com.au/medical-centres/burleigh-heads-QLD-4220/burleigh-optometrists/doctors?wp=w_lightbox"
        title="Book appointments with optometrists at Burleigh Optometrists in Burleigh Heads QLD 4220, through HotDoc"
        target="_blank"
        data-hotdoc-widget="lightbox"
        data-hotdoc-button
        className="large icon-hotdoc"
        style={{ transition: "none" }}>
        Book Appointment
    </a>;

export default HotDoc;