import * as React from 'react'
import Navigation from './Navigation'

export default class Header extends React.Component {
    render() {
        return (
            <header className="header-static navbar-sticky navbar-light">
                <div className="navbar-top d-none d-lg-block">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <ul className="nav list-unstyled ml-3">
                                    <li className="nav-item mr-3">
                                        <a className="navbar-link" href="#"><strong>Address:</strong> Shop 2, 57 James Street Burleigh Heads QLD 4220</a>
                                    </li>
                                    <li className="nav-item mr-3">
                                        <a className="navbar-link" href="mailto:info@burleighoptom.com.au"><strong>Email:</strong> info@burleighoptom.com.au</a>
                                    </li>
                                    <li className="nav-item mr-3">
                                        <a className="navbar-link" href="#"><strong>Phone:</strong> (07) 5535 5999</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex align-items-center">
                                <ul className="social-icons">
                                    <li className="social-icons-item social-facebook m-0">
                                        <a className="social-icons-link w-auto px-2" href="https://www.facebook.com/burleighoptom/">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li className="social-icons-item social-instagram m-0">
                                        <a className="social-icons-link w-auto px-2" href="https://www.instagram.com/burleighoptom/">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <Navigation />
            </header>
        )
    }
}