import * as React from 'react'
import GoogleMapReact from 'google-map-react'

const AnyReactComponent = ({ lat, lng, text }: { lat: number, lng: number, text?: string }) => <div>{text}</div>;

export default class Map extends React.Component {
    location = { lat: -28.0889877, lng: 153.4497794 };

    loaded(map: any, maps: any) {
        new maps.Marker({
            position: { lat: this.location.lat, lng: this.location.lng },
            map: map
        });
    }

    getOptions(maps: any) {
        const mapStyles = [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            }
        ];

        return { styles: mapStyles };
    }

    render() {


        return (
            <div className="h-100">
                <div className="contact-map overflow-hidden">
                    <div className="map-wrapper">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyBgkNGhNcPia--iaNPrIKTyhuSRZFT0KmE' }}
                            defaultCenter={this.location}
                            defaultZoom={17}
                            options={this.getOptions}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }: { map: any, maps: any }) => this.loaded(map, maps)}>
                            <AnyReactComponent
                                
                                {...this.location}
                            />
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        );
    }
    /*
    async componentDidMount() {
     

        const mapStyles = [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            }
        ];

        const map = new window.google.maps.Map(mapElement, {
            center: location,
            mapTypeControl: false,
            styles: mapStyles,
            zoom: 17
        });

        const marker = new window.google.maps.Marker({
            position: location,
            map: map
        });
    }

    render() {
        return (
            <div className="h-100" >
                <div className="contact-map overflow-hidden">
                    <div className="map-wrapper" id="map" data-lat="-28.0889877" data-lng="153.4497794" data-string="Shop 2, 57 James Street Burleigh Heads QLD 4220" data-zoom="17"></div>
                </div>
            </div >
        );
    }*/
}